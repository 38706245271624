import createReducer from '../../util/reduce'

const {PASSPORT_ACTIVATION, PASSPORT_FIND, PASSPORT_INVITE, PASSPORT_RESETPWD, PASSPORT_SIGNIN, PASSPORT_SIGNUP} = require('../key').default

const initialState = {

}

const actionHandler = {
	[PASSPORT_ACTIVATION]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
    [PASSPORT_FIND]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
    [PASSPORT_INVITE]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
    [PASSPORT_RESETPWD]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
    [PASSPORT_SIGNIN]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
    [PASSPORT_SIGNUP]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
}

export default createReducer(initialState, actionHandler)