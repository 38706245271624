import { combineReducers } from 'redux'

import ai from './ai'
import comment from './comment'
import community from './community'
import passport from './passport'
import sharing from './sharing'
import site from './site'
import task from './task'
import user from './user'

export default combineReducers({
    ai,
    comment,
    community,
    passport,
    sharing,
    site,
    task,
    user,
})