import createReducer from '../../util/reduce'

const {SITE_TAG, SITE_CATEGORY, SITE_INST, SITE_LAB, SITE_STATE, SITE_UPLOAD} = require('../key').default

const initialState = {
	tag: [],
    category: [],
    inst: [],
    lab: [],
    ustate: [],
}

const actionHandler = {
	[SITE_TAG]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			tag: error ? [] : payload,
		};
	},
	[SITE_CATEGORY]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			category: error ? [] : payload,
		};
	},
    [SITE_INST]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			inst: error ? [] : payload,
		};
	},
    [SITE_LAB]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			lab: error ? [] : payload,
		};
	},
    [SITE_STATE]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			ustate: error ? [] : payload,
		};
	},
	[SITE_UPLOAD]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
}

export default createReducer(initialState, actionHandler)