import keyMirror from 'key-mirror'

export default keyMirror({

    SITE_TAG: null,
    SITE_CATEGORY: null,
    SITE_INST: null,
    SITE_LAB: null,
    SITE_STATE: null,
    SITE_UPLOAD: null,

    AI_CHAT: null,

    COMMENT_INDEX: null,
    COMMENT_PUBLISH: null,
    USER_COMMENT: null,

    COMMUNITY_INDEX: null,
    COMMUNITY_PUBLISH: null,
    COMMUNITY_RECOMM: null,
    USER_COMMUNITY: null,
    COMMUNITY_DETAIL: null,
    COMMUNITY_ACTION: null,

    SHARING_INDEX: null,
    SHARING_PUBLISH: null,
    USER_SHARING: null,

    TASK_INDEX: null,
    TASK_PUBLISH: null,
    TASK_DETAIL: null,
    USER_TASK: null,
    TASK_VIEWED: null,
    TASK_STAR: null,
    TASK_ACTION: null,

    PASSPORT_ACTIVATION: null,
    PASSPORT_FIND: null,
    PASSPORT_INVITE: null,
    PASSPORT_RESETPWD: null,
    PASSPORT_SIGNIN: null,
    PASSPORT_SIGNUP: null,

    USER_PROFILE: null,
    USER_UPDATE: null,
    
    MESSAGE: null,
    MESSAGE_CHAT: null,
    MESSAGE_READ: null,
    MESSAGE_UNREAD: null,
    MESSAGE_DETAIL: null,

    PRAISE: null,
    PRAISE_DO: null,

    STAR: null,
    STAR_DO: null,

    VCODE: null,
    VCODE_VALID: null,
})