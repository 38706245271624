import React, { Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux'
import configureStore from './redux'

const store = configureStore()

const Main = React.lazy(() => import('./page'))

const App = () => {
	return (
		<Provider store={store}>
			<HashRouter>
				<Suspense>
					<Routes>
						<Route path="/*" element={<Main/>}/>
					</Routes>
				</Suspense>
			</HashRouter>
		</Provider>
	)
}

export default App;
