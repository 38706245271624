import createReducer from '../../util/reduce'

const {COMMENT_INDEX, COMMENT_PUBLISH, USER_COMMENT} = require('../key').default

const initialState = {
    index: {},
    user: {},
}

const actionHandler = {
	[COMMENT_INDEX]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			index: error ? {} : {
				...payload
			},
		};
	},
    [COMMENT_PUBLISH]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
    [USER_COMMENT]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			user: error ? {} : {
				...payload
			},
		};
	},
}

export default createReducer(initialState, actionHandler)
