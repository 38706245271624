import createReducer from '../../util/reduce'

const {USER_PROFILE, USER_UPDATE, MESSAGE, MESSAGE_CHAT, MESSAGE_READ, MESSAGE_UNREAD, MESSAGE_DETAIL, PRAISE, PRAISE_DO, STAR, STAR_DO, VCODE, VCODE_VALID} = require('../key').default

const initialState = {
    user: {},
    message: [],
    unread: {},
    message_detail: [],
    praise: {},
    star: {}
}

const actionHandler = {
	[USER_PROFILE]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			user: error ? {} : {
				...payload
			},
		};
	},
    [USER_UPDATE]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
    [MESSAGE]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			message: error ? [] : payload,
		};
	},
    [MESSAGE_CHAT]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
    [MESSAGE_READ]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
    [MESSAGE_UNREAD]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			unread: error ? {} : {
				...payload
			},
		};
	},
    [MESSAGE_DETAIL]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			message_detail: error ? [] : payload,
		};
	},
    [PRAISE]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			praise: error ? {} : {
				...payload
			},
		};
	},
    [PRAISE_DO]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
    [STAR]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			star: error ? {} : {
				...payload
			},
		};
	},
    [STAR_DO]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
    [VCODE]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
    [VCODE_VALID]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
}

export default createReducer(initialState, actionHandler)