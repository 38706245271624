import createReducer from '../../util/reduce'

const {AI_CHAT} = require('../key').default

const initialState = {
    chat: {},
}

const actionHandler = {
	[AI_CHAT]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			chat: error ? {} : {
				...payload
			},
		};
	},
}

export default createReducer(initialState, actionHandler)