import createReducer from '../../util/reduce'

const {SHARING_INDEX, SHARING_PUBLISH, USER_SHARING} = require('../key').default

const initialState = {
    index: {},
    user: {},
}

const actionHandler = {
	[SHARING_INDEX]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			index: error ? {} : {
				...payload
			},
		};
	},
    [SHARING_PUBLISH]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
    [USER_SHARING]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			user: error ? {} : {
				...payload
			},
		};
	},
}

export default createReducer(initialState, actionHandler)