import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import {createLogger} from 'redux-logger';

import reducers from './reduce';
import asyncAction from './asyncAction'



let middlewares = [
    thunk,
    promise,
    asyncAction,
]

global.debug = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')

if (global.debug) {
    const logger = createLogger()
    middlewares.push(logger)
}


const createAppStore = applyMiddleware(...middlewares)(createStore);

export default function configureStore() {
	const store = createAppStore(reducers);
    return store;
}