import createReducer from '../../util/reduce'

const {TASK_INDEX, TASK_PUBLISH, TASK_DETAIL, USER_TASK, TASK_VIEWED, TASK_STAR, TASK_ACTION} = require('../key').default

const initialState = {
    index: {},
    user: {},
    detail: {},
    viewed: {},
	star: {},
}

const actionHandler = {
	[TASK_INDEX]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			index: error ? {} : {
				...payload
			},
		};
	},
    [TASK_PUBLISH]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
	[TASK_ACTION]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
    [USER_TASK]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			user: error ? {} : {
				...payload
			},
		};
	},
    [TASK_DETAIL]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			detail: error ? {} : {
				...payload
			},
		};
	},
    [TASK_VIEWED]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			viewed: error ? {} : {
				...payload
			},
		};
	},
	[TASK_STAR]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			star: error ? {} : {
				...payload
			},
		};
	},
}

export default createReducer(initialState, actionHandler)