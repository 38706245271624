import createReducer from '../../util/reduce'

const {COMMUNITY_INDEX, COMMUNITY_RECOMM, COMMUNITY_PUBLISH, USER_COMMUNITY, COMMUNITY_DETAIL, COMMUNITY_ACTION} = require('../key').default

const initialState = {
    index: {},
	recomm: [],
    user: {},
    detail: {},
}

const actionHandler = {
	[COMMUNITY_INDEX]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			index: error ? {} : {
				...payload
			},
		};
	},
	[COMMUNITY_RECOMM]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			recomm: error ? [] : payload,
		};
	},
    [COMMUNITY_PUBLISH]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
	[COMMUNITY_ACTION]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
		};
	},
    [USER_COMMUNITY]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			user: error ? {} : {
				...payload
			},
		};
	},
    [COMMUNITY_DETAIL]: (state, action) => {
		const { payload, error, meta = {} } = action;
		return {
			...state,
			detail: error ? {} : {
				...payload
			},
		};
	},
}

export default createReducer(initialState, actionHandler)
